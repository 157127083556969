import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useSearchParams } from 'react-router-dom';
import classnames from 'classnames';

import css from './NoticeCard.module.scss';
import { Hit } from 'models';
import { NOTICES } from 'shared-config';
import { formatDate, formatNumbers } from 'shared/utils';
import { ReactComponent as ClockIcon } from '../../../../images/icon/alarm-clock.svg';
import { ReactComponent as SavingsIcon } from '../../../../images/icon/savings.svg';
import { ReactComponent as LocationIcon } from '../../../../images/icon/location.svg';
import { ReactComponent as CalendarIcon } from '../../../../images/icon/calendar.svg';
import useLocations from 'shared/useLocations';
import { buildUrl } from 'shared/http-common';
import ChipLine from './chipLine/ChipLine';
import ThresholdIcon from './thresholdIcon/ThresholdIcon';

interface Props {
  readonly notice: Hit;
  readonly isFirstInList: boolean;
}

export default function NoticeCard({
  notice,
  isFirstInList
}: Props): ReactElement {
  const { t } = useTranslation('translations');
  const {
    id,
    heading,
    buyer,
    status,
    description,
    type,
    locationId,
    estimatedValue,
    allTypes,
    deadline,
    issueDate,
    publicationDate,
    sentToTed
  } = notice;
  const { flatLocations: locations } = useLocations();
  const [searchParams] = useSearchParams();

  const isResult = allTypes.includes('RESULT');

  const isDeadlineSoon = () => {
    if (!deadline) return false;

    const currentDate = new Date();
    const targetDate = new Date(deadline);
    const oneWeekInMillis = 7 * 24 * 60 * 60 * 1000;
    const isInFuture = currentDate.getTime() < targetDate.getTime();
    const isPastToday = targetDate.getTime() - currentDate.getTime();

    return isInFuture && isPastToday < oneWeekInMillis
      ? `(${t('search.card.deadline_soon')})`
      : '';
  };

  const getDeadline = () => {
    return deadline ? formatDate(deadline) : t('common.not_specified');
  };

  const getIssueDate = () => {
    if (publicationDate) {
      return formatDate(publicationDate);
    } else {
      return formatDate(issueDate);
    }
  };

  const getLocationName = (locationIds: string[]) => {
    const locationNames: string[] = [];
    locationIds.forEach((locationId) => {
      const location = locations?.find(
        (location) => location.id === locationId
      );
      if (location) {
        locationNames.push(location.name);
      }
    });

    return locationNames.length
      ? locationNames.join(', ')
      : t('common.not_specified');
  };

  const getBuyerNames = () => buyer.map((b) => b.name).join(', ');

  const getEstimatedValue = () => {
    if (!estimatedValue) return t('common.not_specified');

    return `${formatNumbers(estimatedValue.amount.toFixed(0).toString())} ${
      estimatedValue.currencyCode
    }`;
  };

  return (
    <li data-cy={heading}>
      <Link
        id={isFirstInList ? 'notice-link' : undefined}
        to={buildUrl([NOTICES, id])}
        className={css.card}
        state={{ queryString: searchParams.toString() }}
      >
        <div className={css.header}>
          <p
            className={css.buyer}
            aria-label={`${t('common.buyer')}: ${getBuyerNames()}`}
          >
            {getBuyerNames()}
          </p>
          <ThresholdIcon isSentToTed={sentToTed} />
        </div>
        <h2
          className={css.title}
          data-cy="notice-card-title"
          aria-label={`${t('search.card.title')}: ${heading}`}
        >
          {heading}
        </h2>
        <p
          className={css.ingress}
          data-cy="notice-card-description"
          aria-label={`${t('search.card.description')}: ${description}`}
        >
          {description}
        </p>
        <div className={css.bottom_line}>
          <ChipLine status={status} type={type} allTypes={allTypes} />
          <p
            className={css.location}
            data-cy="notice-card-location"
            aria-label={`${t('filter.location')}: ${getLocationName(
              locationId
            )}`}
          >
            <LocationIcon />
            {getLocationName(locationId)}
          </p>
          <p
            className={css.est_value}
            aria-label={`${t('filter.value')}: ${getEstimatedValue()}`}
          >
            <SavingsIcon />
            {getEstimatedValue()}
          </p>
          <p
            className={css.issue_date}
            aria-label={`${t('filter.date')}: ${getIssueDate()}`}
          >
            <CalendarIcon />
            {getIssueDate()}
          </p>
          {!isResult && (
            <p
              data-cy="notice-card-deadline"
              className={classnames(
                css.deadline,
                isDeadlineSoon() && css.soon_deadline
              )}
              aria-label={`${t(
                'DEADLINE'
              )}: ${getDeadline()} ${isDeadlineSoon()}`}
            >
              <ClockIcon />
              {getDeadline()} {isDeadlineSoon()}
            </p>
          )}
        </div>
      </Link>
    </li>
  );
}
